/* eslint-disable camelcase */
import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import img1 from 'img/help/gtm/gtm_1.png';
import img2 from 'img/help/gtm/gtm_2.png';
import img3 from 'img/help/gtm/gtm_3.png';
import img3_1 from 'img/help/gtm/gtm3_1.png';
import img3_2 from 'img/help/gtm/gtm3_2.png';
import img4 from 'img/help/gtm/gtm_4.png';
import img5 from 'img/help/gtm/gtm_5.png';
import img6 from 'img/help/gtm/gtm_6.png';
import img7 from 'img/help/gtm/gtm_7.png';
import img8 from 'img/help/gtm/gtm_8.png';
import logo from 'img/help/integrations/gtm.png';

const Content = () => {
  return (
    <div>
      To use LiveSession, you need to include a piece of simple JavaScript code on your website. We
      provide a custom template to save your time and make the integration as easy as possible.
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <p>
          <strong>Important</strong>: You need to meet these two conditions:
        </p>
        <ul style={{ margin: 0 }}>
          <li>Your visitors have to use HTTPS</li>
          <li>Your website needs an SSL certificate.</li>
        </ul>
      </div>
      <p>Here’s how install the LiveSession tracking code using Google Tag Manager.</p>
      <ul className="list-unstyled">
        <li>1. Go to your LiveSession account and go to Settings &gt; Websites.</li>
        <li>
          2. Select <i>Tracking code</i> and copy your TrackID.
        </li>
        <li>
          3. Log in to your Google Tag Manager Account and select <i>Add a new tag</i>:
          <Image src={img1} alt="Google Tag Manager integration" title="Open GTM dashboard" />
        </li>
        <li>
          4. Choose a tag:
          <Image src={img2} alt="Google Tag Manager integration" title="Choose a tag" />
        </li>
        <li>
          5. Search for <i>LiveSession</i>, select LiveSession Tag and click <i>Add to workspace</i>
          :
          <Image
            src={img3}
            alt="Google Tag Manager integration"
            title="Search for tag in Gallery"
          />
        </li>
        <Image src={img3_1} alt="Google Tag Manager integration" title="Search for LiveSession" />
        <Image src={img3_2} alt="Google Tag Manager integration" title="Add to workspace" />
        <li>
          6. Paste your TrackID. You can also fill the rest of the fields, but they are optional.
          You can find more details about them{' '}
          <a
            href="https://developers.livesession.io/javascript-api/configuration/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
          <Image src={img4} alt="Google Tag Manager integration" title="Paste TrackID" />
        </li>
        <li>
          7. Choose a trigger, select <i>All Pages</i> to enable LiveSession on every page of your
          website:
          <Image src={img5} alt="Google Tag Manager integration" title="All pages" />
        </li>
        <li>
          8. Save your tag:
          <Image src={img6} alt="Google Tag Manager integration" title="Save" />
        </li>
        <li>
          9. After that click on <strong>Submit</strong>.
          <Image src={img7} alt="Google Tag Manager integration" title="Publish" />
        </li>
        <li>
          10. Finally add info about your change and click <strong>Publish</strong>.
          <Image src={img8} alt="Google Tag Manager integration" title="Click Publish again" />
        </li>
        <li>
          Congrats, you&apos;re ready to go! Check if your script works properly using{' '}
          <Link href="/help/how-to-check-if-my-tracking-script-works/"> this guide</Link>.
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script/',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = Article(Content);

export const frontmatter = {
  metaTitle: 'How to install LiveSession using Google Tag Manager?',
  metaDescription:
    'Add LiveSession to your website in few easy steps with Google Tag Manager help.',
  canonical: '/help/how-install-with-google-tag-manager/',
  logo,
  logoStyle: {
    maxWidth: 150,
  },
};

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Get Started" {...frontmatter} />
);
